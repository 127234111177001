/**
 * @module Validators
 * @description Some handy validators
 */

/**
 * @function isValidZipInput
 * @description Check for zip 5 and numeric input, Does not allow for Zip9
 * @param { number } value zip input
 */
export const isValidZipInput = ( value ) => value.length === 5 && ! Number.isNaN( value );

/**
 * @function isValidAddressInput
 * @description Check if address contains street name and suffix (street, avenue, etc)
 * Number is optional i.e. 7th Street & 123 7th Street are both acceptable
 * @param { string } value address input
 */
export const isValidAddressInput = ( value ) => {
  const regex = /^(?:(\d+\s))?[A-z0-9]+\s[A-z]+/;

  if ( ! value ) {
    return false;
  }

  return regex.test( value );
};

/**
 * @function isValidEmail
 * @description Check if email address contains @ and . surrounded by non-whitespace characters
 * @param { string } value email input
 */
export const isValidEmail = ( value ) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if ( ! value ) {
    return false;
  }

  return regex.test( value );
};

/**
 * @function standardRegex
 * @description
 * Regex strings
 * Base: letter, number, & backspace
 * Name: letter, space, & backspace
 * Email: letter, number, @, period(.), & backspace
 * Date: number only
 * Address: letter, number, space, period(.), and backspace
 */
export const standardRegex = {
  base: /^[a-zA-Z0-9\b]/,
  name: /[a-zA-Z\s\b]/,
  email: /[a-zA-Z0-9@.\b]/,
  date: /[0-9]/,
  address: /^[a-zA-Z0-9\s.\b]/
};
