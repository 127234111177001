/**
 * @module Viewport State
 * @description
 */
export const state = {
  desktop_initialized: false,
  is_desktopXL: false,
  is_desktop: false,
  is_tablet: false,
  is_mobile: false,
  mobile_initialized: false,
  v_height: 0,
  v_width: 0
};

export default state;
