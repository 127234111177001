/**
 * @module Filters
 * @description Some handy filters
 */

/**
 * @function stringify
 * @description Filter for Tagular Payload Object
 */
export const stringify = ( str ) => JSON.stringify( str );

/**
 * @function onlySpaces
 * @description Check if string is only spaces
 */
export const onlySpaces = ( str ) => str.trim().length === 0;

export default stringify;
